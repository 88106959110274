<template>
  <div>
    <div
      class="text-center"
      v-if="isLoading"
    >
      <v-progress-circular
        class="text-center"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!isLoading">
      <v-card outlined>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <PhoneInput
                      :value="detail.phone"
                      @change="detail.phone = $event"
                      :can-remove="false"
                      :mask="phoneMask"
                      input-class="pb-10"
                      phone-cols="12"
                    />
                    <v-select
                      v-model="detail.owner_status"
                      :items="ownerStatuses"
                      item-value="id"
                      item-text="value"
                      no-data-text="Не установлено"
                      class="pt-2"
                      multiple
                      label="Статус владельца"
                    ></v-select>
                    <v-textarea
                      outlined
                      label="Комментарий"
                      rows="2"
                      v-model="detail.description"
                    ></v-textarea>
                    <v-text-field
                      label="Имя"
                      v-model="detail.name"
                    ></v-text-field>
                    <v-select
                      v-model="detail.status"
                      :items="phoneStatuses"
                      item-value="id"
                      item-text="value"
                      placeholder="Статус телефона"
                      label="Статус телефона"
                      class="pt-2"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <CallHistory
                      v-model="detail.call_history"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <div class="text-right saveBlock">
        <v-btn
          class="mr-2"
          small
          dark
          color="light-green"
          @click="saveDetail"
          :loading="isSavingData"
        >
          <v-icon class="pr-3">mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import formatters from '@/components/mixins/formatters'
import PhoneInput from '@/components/views/admin/common/BasePhone/PhoneInput'
import CallHistory from '@/components/views/admin/common/BasePhone/CallHistory'
import phoneComputed from '@/components/mixins/phoneComputed'
import * as Config from '@/config'

export default {
  components: { CallHistory, PhoneInput },
  mixins: [detail, formatters, phoneComputed],
  name: 'Detail',
  data: () => ({
    componentCategory: 'phone',
    callHistoryItem: {
      status: '',
      comment: ''
    }
  }),
  methods: {
    async hasErrors () {
      let errors = false
      if (this.detail.phone.length < 11) {
        errors = 'Неверный формат номера телефона'
      }
      return errors
    }
  },
  computed: {
    phoneMask () {
      return Config.phoneMask
    }
  }
}
</script>

<style scoped>

</style>
