<template>
  <v-text-field
    :label="$t('phone.phone')"
    :class="inputClass"
    v-model="maskedPhone"
    @change.self="handleChangePhone"
    @keyup.enter="handleKeyupEnter"
    v-mask="{mask: mask, unmaskedVar: 'clearValue'}"
  ></v-text-field>
</template>

<script>
import { mask } from '@titou10/v-mask'

export default {
  name: 'PhoneInput',
  directives: {
    mask
  },
  props: {
    value: String,
    mask: String,
    inputClass: String
  },
  data: function () {
    return {
      maskedPhone: this.value,
      clearValue: null
    }
  },
  methods: {
    handleChangePhone () {
      if (this.clearValue && this.clearValue.length === 11) {
        this.$emit('change', this.clearValue)
      } else {
        this.maskedPhone = this.value
      }
    },
    handleKeyupEnter () {
      this.handleChangePhone()
      this.$emit('save')
    }
  }
}
</script>

<style scoped>

</style>
