<template>
  <v-col cols="12">
    <div class="title">{{$t('phone.call_history')}}</div>
    <div
      class="message"
      v-if="value"
    >
      <div
        class="pa-2 ma-0"
        v-for="(historyItem, index) in value"
        :key="index"
      >
        <v-menu
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-chip
              link
              :class="phoneCallClass(historyItem.status)"
              v-on="on"
            >
              <strong class="pr-1">{{historyItem.date}}</strong>
              <span>{{formatValue('phone.call_history', historyItem.status)}}</span>
            </v-chip>
          </template>
          <v-card>
            <v-card-text>
              <span>{{historyItem.date}}</span> <span>{{historyItem.user}}</span><br>
              <span>{{formatValue('phone.call_history', historyItem.status)}}</span><br>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-select
              v-model="callHistoryItem.status"
              :items="callStatuses"
              item-value="id"
              item-text="value"
              label="Статус созвона"
              class="pa-2"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            class="pa-0 text-right"
          >
            <v-btn
              :disabled="!callHistoryItem.status"
              color="indigo"
              dark
              x-small
              @click="addCallHistoryItem"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'CallHistory',
  props: {
    value: Array
  },
  data: function () {
    return {
      isOpen: false,
      callHistoryItem: {
        status: ''
      }
    }
  },
  computed: {
    callStatuses () {
      return this.$store.getters['serviceData/optionsList']('phone.call_history')
    },
    userName () {
      return this.$store.getters['auth/getName']
    }
  },
  methods: {
    addCallHistoryItem () {
      if (!this.value) {
        this.$set(this.value, 'call_history', [])
      }
      this.value.unshift({
        date: (new Date()).toLocaleString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          weekday: 'short',
          hour: '2-digit',
          minute: '2-digit'
        }),
        status: this.callHistoryItem.status,
        user: this.userName
      })
      this.isOpen = false
      this.callHistoryItem.status = null
    }
  }
}
</script>

<style scoped>
.message {
  max-height: 65vh;
  overflow-y: auto;
}
</style>
