export default {
  props: {
    value: Object,
    isAdvertPhone: Boolean
  },
  computed: {
    phoneStatuses () {
      return this.$store.getters['serviceData/optionsList']('phone.status', false)
    },
    ownerStatuses () {
      return this.$store.getters['serviceData/optionsList']('phone.owner_status', false)
    },
    ownerName: {
      get () {
        return this.value.phone.name
      },
      set: function (value) {
        this.value.phone.name = value
      }
    },
    ownerStatus: {
      get () {
        return this.value.phone.owner_status
      },
      set: function (value) {
        this.value.phone.owner_status = value
      }
    },
    phoneStatus: {
      get () {
        return this.value.phone.status
      },
      set (value) {
        this.value.phone.status = value
      }
    },
    agentDescription: {
      get () {
        return this.value.phone.description
      },
      set (value) {
        this.value.phone.description = value
      }
    },
    itemDescription: {
      get () {
        return this.value.description
      },
      set (value) {
        this.value.description = value
      }
    }
  }
}
